import { type MouseEvent } from 'react';
import classNames from 'classnames';

import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { GA4EventName, GA4FeatureCategory } from '@jsmdg/tracking';
import { ChevronIcon, PureLink } from '@jsmdg/yoshi';
import { type NavigationItem, type NavigationItemParent } from '../../../../shared/types';
import { getLinkWithLocationParams } from '../../../helper/getLinkWithLocationParams';
import {
    HIDDEN_ITEM_IDS,
    SPECIAL_HIGHLIGHTED_ITEM,
} from '../../../helper/specialNavigationItemIds';
import {
    availableTrackingActions,
    trackingCategories,
    trackNavigationClick,
} from '../../../helper/trackNavigation';
import { type HeaderContext } from '../../../types';
// eslint-disable-next-line import/no-cycle
import { BurgerNavigationGroup } from '../BurgerNavigationGroup/BurgerNavigationGroup';
import styles from './BurgerNavigationItem.module.scss';

type BurgerNavigationItemProps = {
    readonly level: number;
    readonly item?: NavigationItem;
    readonly toggleFunction: (level: number, string?: string) => void;
    readonly isSelfReferrer?: boolean;
    readonly isNavigationTitle?: boolean;
    readonly parentId?: string;
    readonly trackingLabel?: string;
};

const BurgerNavigationItem = ({
    isNavigationTitle = false,
    isSelfReferrer = false,
    item = {} as NavigationItem,
    level,
    parentId,
    toggleFunction,
    trackingLabel,
}: BurgerNavigationItemProps): JSX.Element => {
    const { isMydays } = useFragmentContext<HeaderContext>();
    const { children, id, title, uri } = item;
    const isNotTopLevel = level !== 0;
    const isItemHidden = HIDDEN_ITEM_IDS.includes(id);

    function onOpenCategoryClick(e: React.UIEvent | React.KeyboardEvent): void {
        toggleFunction(level, id);
        trackNavigationClick({
            e,
            action: availableTrackingActions.OPEN,
            category: trackingCategories.BURGER_NAVI,
            label: trackingLabel,
            eventData: {
                eventName: GA4EventName.OpenNavigationItem,
                feature_category: GA4FeatureCategory.BurgerNavigation,
                click_element: trackingLabel?.replace(/_/g, ' > '),
                click_text: item.title,
                navigationlevel: level,
            },
        });
    }

    const onLinkClick = (e: MouseEvent): void => {
        e.preventDefault();
        const linkUrl = getLinkWithLocationParams(uri);
        trackNavigationClick({
            e,
            action: availableTrackingActions.CLICK,
            category: trackingCategories.BURGER_NAVI,
            label: trackingLabel,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.BurgerNavigation,
                click_element: trackingLabel?.replace(/_/g, ' > '),
                click_text: item.title,
                navigationlevel: level,
                click_url: window.location.origin + linkUrl,
            },
        });
        window.location.href = linkUrl;
    };

    if (children && children.length && level < (isMydays ? 3 : 2)) {
        return (
            <li data-level={level} className={styles.navigationItem}>
                <div
                    className={classNames(
                        styles.navigationItemContent,
                        'd-flex justify-content-between align-items-center',
                        {
                            [styles.highlighted]:
                                id === SPECIAL_HIGHLIGHTED_ITEM || (parentId === id && level === 0),
                        },
                    )}
                    onClick={(e: React.UIEvent | React.KeyboardEvent) => onOpenCategoryClick(e)}
                    onKeyDown={(e: React.UIEvent | React.KeyboardEvent) => onOpenCategoryClick(e)}
                    role="button"
                    tabIndex={0}
                >
                    {title}
                    <ChevronIcon className={styles.chevron} />
                </div>
                {isNotTopLevel && (
                    <BurgerNavigationGroup
                        items={children}
                        level={level + 1}
                        parent={{ id, uri, title } as NavigationItemParent}
                        toggleFunction={toggleFunction}
                        className="p-0"
                        parentTrackingLabel={trackingLabel}
                    />
                )}
            </li>
        );
    }

    return (
        <li
            data-level={level}
            className={classNames(styles.navigationItem, {
                invisible: isItemHidden,
                [styles.hiddenItem]: isItemHidden,
            })}
        >
            <PureLink
                internal
                className={classNames(
                    styles.navigationItemContent,
                    styles.navigationItemLink,
                    'd-flex align-items-center',
                    {
                        [styles.selfReferrer]: level === 2 && isSelfReferrer,
                        [styles.highlighted]: id === SPECIAL_HIGHLIGHTED_ITEM,
                        [styles.groupHeader]: isNavigationTitle,
                    },
                )}
                href={uri}
                // remove spreaded props when rendition of judgement on AT may be removed
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...(id === 'urteilsveroeffentlichung' && {
                    target: '_blank',
                    rel: 'noopener noreferrer',
                })}
                onClick={onLinkClick}
            >
                {title}
            </PureLink>
        </li>
    );
};

export { BurgerNavigationItem };
